import {
  CalendarBiLogger,
  createCalendarBiLogger,
} from '../bi/biLoggerFactory';
import { GetBusinessResponse } from '@wix/ambassador-services-catalog-server/types';
import {
  CalendarState,
  TFunction,
} from '../../components/BookingCalendar/controller';
import { CalendarApi } from '../../api/CalendarApi';
import { ControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';
import Experiments from '@wix/wix-experiments';
import { Preset } from '../../types/types';
import { ISettingsParams } from '../../components/BookingCalendar/settingsParams';
import { findStaffVariant } from '../dynamicPricing/dynamicPricing';
import { DO_NOT_CARE_STAFF } from '../../constants/constants';
import type { CalendarSelections } from '@wix/bookings-catalog-calendar-viewer-utils';
import {
  BookingsQueryParams,
  getCountryCode,
  getDateRegionalSettingsLocale,
  getLanguage,
  getLocale,
  getUrlQueryParamValue,
  isCartEnabled as isCartEnabledUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { SlotAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { ISettingsParam } from '@wix/tpa-settings';
import type { GetAggregatedInfoResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import {
  BusinessPropertyName,
  isPropertyEnabled,
  getCountryCode as getCountryCodeFromBusinessInfo,
} from '@wix/bookings-calendar-catalog-viewer-mapper';

export type GetContentParams = {
  settingsParam: ISettingsParam;
  translationKey: string;
  options?: object;
};

export type LanguageSettings = {
  language: string;
  dateRegionalSettingsLocale: string;
  regionalSettingsLocale: string;
  regionalSettingsIntlLocale: string;
  countryCode: string;
};

export type GetContent = (params: GetContentParams) => string;

export type CalendarContext = {
  biLogger: CalendarBiLogger;
  businessInfo?: GetBusinessResponse | GetAggregatedInfoResponse;
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  isMobile: boolean;
  calendarApi: CalendarApi;
  settings: ControllerFlowAPI['settings'];
  experiments: Experiments;
  reportError: ReportError;
  t: TFunction;
  getContent: GetContent;
  settingsParams: Record<keyof ISettingsParams,="" any="">;
  preset: preset;
  hasReferralParam: boolean;
  isCartEnabled: boolean;
  isMultiServiceAppointmentEnabled: boolean;
  calendarSelections?: CalendarSelections;
  flowAPI: ControllerFlowAPI;
  isDateAndTimeViewMode: boolean;
  reloadWidget: () => Promise<void>;
  subscriptions: {
    onNextClicked?: (args: {
      service: Service;
      slotAvailability?: SlotAvailability;
    }) => Promise<{ shouldNavigate: boolean }>;
  };
  languageSettings: LanguageSettings;
};

export async function createCalendarContext({
  flowAPI,
  businessInfo = {},
  calendarApi,
  initialState,
  isPricingPlanInstalled,
  isMemberAreaInstalled,
  settingsParams,
  preset,
  calendarSelections,
  reloadWidget,
  isDateAndTimeViewMode,
  subscriptions,
}: {
  flowAPI: ControllerFlowAPI;
  businessInfo: GetAggregatedInfoResponse | GetBusinessResponse;
  calendarApi: CalendarApi;
  initialState: CalendarState;
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  settingsParams: any;
  preset: Preset;
  calendarSelections?: CalendarSelections;
  reloadWidget: () => Promise<void>;
  isDateAndTimeViewMode: boolean;
  subscriptions: CalendarContext['subscriptions'];
}): Promise<calendarcontext> {
  const {
    settings,
    translations: { t },
    reportError,
    experiments,
    environment: { isMobile },
  } = flowAPI;
  const getContent = ({
    settingsParam,
    translationKey,
    options = {},
  }: GetContentParams) =>
    settings.get(settingsParam) || t(translationKey, options);

  const hasReferralParam = !!getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    BookingsQueryParams.REFERRAL,
  );

  const isCartEnabled = businessInfo
    ? await isCartEnabledUtils(
        flowAPI.controllerConfig.wixCodeApi,
        businessInfo,
      )
    : false;

  if (calendarSelections) {
    calendarSelections.services.forEach((service) => {
      const { id, resources } = service;
      const serviceVariants = initialState.serviceVariantsMap[id];

      if (resources && resources[0] !== DO_NOT_CARE_STAFF) {
        const staffMemberId = resources[0];
        const selectedVariant = findStaffVariant({
          serviceVariants,
          staffMemberId,
        });

        if (selectedVariant) {
          service.variants = [selectedVariant];
        }
      }

      if (service.variants?.[0]) {
        service.variants[0].numberOfParticipants =
          service.variants[0].numberOfParticipants ?? 1;
      }
    });
  }

  const isMultiServiceAppointmentEnabled = isPropertyEnabled(
    businessInfo,
    BusinessPropertyName.MULTI_SERVICES_APPOINTMENTS,
  );

  const languageSettings = getLanguageSettings(flowAPI, businessInfo);

  const contextWithoutBiLogger = {
    flowAPI,
    businessInfo,
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    calendarApi,
    settings,
    experiments,
    reportError,
    t,
    getContent,
    isMobile,
    settingsParams,
    preset,
    hasReferralParam,
    isCartEnabled,
    calendarSelections,
    reloadWidget,
    isDateAndTimeViewMode,
    subscriptions,
    isMultiServiceAppointmentEnabled,
    languageSettings,
  };

  const biLogger = createCalendarBiLogger(
    flowAPI,
    initialState,
    settings,
    settingsParams,
    preset,
    contextWithoutBiLogger,
    languageSettings,
  );

  return {
    ...contextWithoutBiLogger,
    biLogger,
  };
}

export const getLanguageSettings = (
  flowAPI: ControllerFlowAPI,
  businessInfo: GetBusinessResponse | GetAggregatedInfoResponse,
) => {
  const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
  const locale = getLocale(wixCodeApi);
  return {
    language: getLanguage(wixCodeApi),
    dateRegionalSettingsLocale: getDateRegionalSettingsLocale(wixCodeApi),
    regionalSettingsLocale: locale,
    regionalSettingsIntlLocale: locale,
    countryCode:
      getCountryCode(wixCodeApi) ||
      getCountryCodeFromBusinessInfo(businessInfo) ||
      '',
  };
};
</calendarcontext></void></void></keyof>